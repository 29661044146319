export const Images = [
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550767/sacynbugxl3womux99xw.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550767/sacynbugxl3womux99xw.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550765/vsyvtcj05qj8tsnqncfz.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550765/vsyvtcj05qj8tsnqncfz.jpg",
    },
    {
        original: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550766/aoet7sjy0tcngmtzhubb.jpg",
        thumbnail: "https://res.cloudinary.com/dr2kfppse/image/upload/v1730550766/aoet7sjy0tcngmtzhubb.jpg",
    },
    {
        original: "https://www.al-monitor.com/sites/default/files/styles/article_header/public/almpics/2017/03/RTR2Y1RF.jpg/RTR2Y1RF.jpg?h=f7822858&itok=OyrsgX7-",
        thumbnail: "https://www.al-monitor.com/sites/default/files/styles/article_header/public/almpics/2017/03/RTR2Y1RF.jpg/RTR2Y1RF.jpg?h=f7822858&itok=OyrsgX7-",
    },
    {
        original: "https://res.cloudinary.com/dcxwlwsgd/image/upload/v1722760449/WhatsApp_Image_2024-08-04_at_14.02.26_4_f98ffn.jpg",
        thumbnail: "https://res.cloudinary.com/dcxwlwsgd/image/upload/v1722760449/WhatsApp_Image_2024-08-04_at_14.02.26_4_f98ffn.jpg",
    },
];